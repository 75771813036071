var render = function render(){
  var _vm$selectedSourceTem, _vm$selectedServiceTe, _vm$integrations$find;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "bg-lighter rounded-md p-4 mb-3"
  }, [_vm.loadingAction.integrations ? _c('Spinner') : _c('div', [_c('FormGroup', {
    attrs: {
      "id": "description",
      "label": "Description",
      "error": _vm.validationErrors.description,
      "description": "A friendly description of the automation"
    },
    model: {
      value: _vm.form.description,
      callback: function ($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "category",
      "label": "Category",
      "type": "select",
      "options": _vm.categoryOptions,
      "error": _vm.validationErrors.category,
      "description": "What category does this automation apply to? For example, if data comes in via a company-wide email receiver then this would be 'Company'. If the automation fetches data for a single meter, then this would be 'Account'."
    },
    model: {
      value: _vm.form.category,
      callback: function ($$v) {
        _vm.$set(_vm.form, "category", $$v);
      },
      expression: "form.category"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "source",
      "label": "Source",
      "type": "select",
      "options": _vm.sourceOptions,
      "error": _vm.validationErrors.source,
      "description": "Which source does this data come from?"
    },
    model: {
      value: _vm.form.source,
      callback: function ($$v) {
        _vm.$set(_vm.form, "source", $$v);
      },
      expression: "form.source"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "service",
      "label": "File Type or Third Party",
      "type": "select",
      "options": _vm.serviceOptions,
      "error": _vm.validationErrors.service,
      "placeholder": "Select a service..."
    },
    model: {
      value: _vm.form.service,
      callback: function ($$v) {
        _vm.$set(_vm.form, "service", $$v);
      },
      expression: "form.service"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "frequency",
      "label": "Frequency",
      "type": "select",
      "options": _vm.frequencyOptions,
      "error": _vm.validationErrors.frequency,
      "description": "How often this task is scheduled to run. 'Email Receiver' will run the task when an email is received."
    },
    model: {
      value: _vm.form.frequency,
      callback: function ($$v) {
        _vm.$set(_vm.form, "frequency", $$v);
      },
      expression: "form.frequency"
    }
  }), _vm.form.category == 'account' ? _c('div', [_vm.accountLoadingAction.list ? _c('Spinner', {
    attrs: {
      "size": "sm"
    }
  }) : _vm._e(), _c('FormGroup', {
    attrs: {
      "id": "accountIds",
      "label": "Account",
      "type": "select-array-search",
      "options": _vm.accountOptions,
      "error": _vm.validationErrors.accountId,
      "placeholder": "Select an account...",
      "description": "Accounts to apply this automation to"
    },
    model: {
      value: _vm.form.accountIds,
      callback: function ($$v) {
        _vm.$set(_vm.form, "accountIds", $$v);
      },
      expression: "form.accountIds"
    }
  })], 1) : _vm._e(), _vm.form.category == 'asset' ? _c('FormGroup', {
    attrs: {
      "id": "assetId",
      "label": "Assets",
      "type": "select-array-list",
      "options": _vm.assetOptions,
      "error": _vm.validationErrors.assetId,
      "placeholder": "Select an asset...",
      "description": "Assets to apply this automation to"
    },
    model: {
      value: _vm.form.assetId,
      callback: function ($$v) {
        _vm.$set(_vm.form, "assetId", $$v);
      },
      expression: "form.assetId"
    }
  }) : _vm._e(), _c('FormGroup', {
    attrs: {
      "id": "active",
      "label": "Active",
      "type": "radio",
      "options": [{
        label: 'Yes',
        value: true
      }, {
        label: 'No',
        value: false
      }]
    },
    model: {
      value: _vm.form.active,
      callback: function ($$v) {
        _vm.$set(_vm.form, "active", $$v);
      },
      expression: "form.active"
    }
  })], 1)], 1), (_vm$selectedSourceTem = _vm.selectedSourceTemplate) !== null && _vm$selectedSourceTem !== void 0 && _vm$selectedSourceTem.form ? _c('div', {
    staticClass: "bg-lighter rounded-md p-4 mb-3"
  }, [_c('h4', {
    staticClass: "text-capitalize mb-3"
  }, [_vm._v(_vm._s(_vm.sourceOptions.find(function (source) {
    var _vm$form;
    return source.value === ((_vm$form = _vm.form) === null || _vm$form === void 0 ? void 0 : _vm$form.source);
  }).label) + " options")]), _c('p', [_vm._v(_vm._s(_vm.selectedSourceTemplate.description))]), _vm._l(Object.keys(_vm.selectedSourceTemplate.form), function (key) {
    return _c('FormGroup', {
      key: `${_vm.form.source}-${key}`,
      attrs: {
        "id": `${_vm.form.source}-${key}`,
        "label": _vm.selectedSourceTemplate.form[key].label,
        "type": _vm.selectedSourceTemplate.form[key].type,
        "description": _vm.selectedSourceTemplate.form[key].description,
        "options": _vm.selectedSourceTemplate.form[key].options,
        "date-format": _vm.selectedSourceTemplate.form[key].dateFormat,
        "placeholder": _vm.selectedSourceTemplate.form[key].placeholder
      },
      model: {
        value: _vm.form.sourceData[key],
        callback: function ($$v) {
          _vm.$set(_vm.form.sourceData, key, $$v);
        },
        expression: "form.sourceData[key]"
      }
    });
  })], 2) : _vm._e(), (_vm$selectedServiceTe = _vm.selectedServiceTemplate) !== null && _vm$selectedServiceTe !== void 0 && _vm$selectedServiceTe.form ? _c('div', {
    staticClass: "bg-lighter rounded-md p-4 mb-3"
  }, [_c('h4', {
    staticClass: "text-capitalize mb-3"
  }, [_vm._v(_vm._s((_vm$integrations$find = _vm.integrations.find(function (integration) {
    return integration.service === _vm.form.service;
  })) === null || _vm$integrations$find === void 0 ? void 0 : _vm$integrations$find.name) + " options")]), _c('p', [_vm._v(_vm._s(_vm.selectedServiceTemplate.description))]), _vm._l(Object.keys(_vm.selectedServiceTemplate.form), function (key) {
    return _c('FormGroup', {
      key: `${_vm.form.service}-${key}`,
      attrs: {
        "id": `${_vm.form.service}-${key}`,
        "label": _vm.selectedServiceTemplate.form[key].label,
        "type": _vm.selectedServiceTemplate.form[key].type,
        "description": _vm.selectedServiceTemplate.form[key].description,
        "options": _vm.selectedServiceTemplate.form[key].options,
        "date-format": _vm.selectedServiceTemplate.form[key].dateFormat,
        "placeholder": _vm.selectedServiceTemplate.form[key].placeholder
      },
      model: {
        value: _vm.form.data[key],
        callback: function ($$v) {
          _vm.$set(_vm.form.data, key, $$v);
        },
        expression: "form.data[key]"
      }
    });
  })], 2) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }